import { translate } from '@/messages'
export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text:  "ФИО заявителя",
    align: 'start',
    value: 'declarant_full_name'
  },
  {
    text:  "Дата",
    align: 'start',
    value: 'declaration_date'
  },
  {
    text:  "Адрес объекта",
    align: 'start',
    value: 'object_address'
  }
]
