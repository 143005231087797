<template>
  <div :key="keyFilterForm">
    <BuilderText
      name="q"
      data-type="string"
      @input="handleText($event, 'q')"
      label="Поиск по ФИО заявителя"
    />
  </div>
</template>

<script>

import BuilderText from '@/components/base/forms/BuilderText'
import BaseBtn from '@/components/base/UI/BaseBtn'
import filterCommon from '@/components/mixins/filterCommon'

export default {
  mixins: [filterCommon],
  name: 'FilterPanel',
  components: { BaseBtn, BuilderText },
  data () {
    return {
      model: {}
    }
  },
  methods: {
    handleText (data, name) {
      this.$set(this.model, name, data)
    }
  },
  watch: {
    model: {
      handler () {
        if (Object.keys(this.model).length === 0) {
          this.$emit('emptyField', true)
        } else {
          return this.$emit('change', this.model)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
