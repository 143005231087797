<template>
  <tr
    @click="detail(item.id)"
  >
    <td>{{item.id}}</td>
    <td>{{item.declarant_full_name}}</td>
    <td>{{timestampToDate(item.declaration_date)}}</td>
    <td>{{item.object_address}}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'

export default {
  mixins: [formatters, listItem],
  name: 'ListItem'
}
</script>

<style scoped>

</style>
