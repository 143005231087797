<template>
  <div>
    <BaseList
      :headers="headers"
      :controller="'request-felling-ticket'"
      :is-filter="true"
      :query="{filter}"
      @filter="handleFilter"
    >
      <template #item="{item}">
        <ListItem :item="item"/>
      </template>
    </BaseList>
    <BaseDialog
      label="Фильтр"
      :max-width="800"
      hideScroll
      v-model="isDialogFilter"
      is-cross-close
    >
      <template #content>
        <BaseFilter
          @handleSearch="handleSearch"
          @clearFilter="handleClear"
        >
          <template #filters>
            <FilterPanel
              @change="changeField"
              @emptyField="emptyField"
            />
          </template>
        </BaseFilter>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import FilterPanel from './list/FilterPanel'
import ListItem from './list/ListItem'
import BaseList from '@/components/base/BaseList'
import headers from './list/headers'
import BaseDialog from '@/components/base/BaseDialog'
import BaseFilter from '@/components/base/BaseFilter'
import { verificationScenarioByRole } from '@/components/utils/common'
import filter from '@/components/mixins/filter'

export default {
  mixins: [filter],
  name: 'List',
  components: { BaseList, ListItem, FilterPanel, BaseDialog, BaseFilter },
  data () {
    return {
      headers: headers,
      filter: {}
    }
  }
}
</script>

<style scoped>

</style>
